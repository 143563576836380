/* Style the table */
table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    background-color: white;
  }
  
  /* Style table headers */
  th, td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }
  
  /* Alternate row colors */
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  /* Hover effect on rows */
  tr:hover {
    background-color: #e0e0e0;
  }
  
  /* Style the header row */
  th {
    background-color: #4CAF50;
    color: white;
  }
  
  /* Responsive table on smaller screens */
  @media (max-width: 600px) {
    th, td {
      display: block;
      width: 100%;
      box-sizing: border-box;
    }
  
    /* Center-align text in headers */
    th, td {
      text-align: center;
    }
  
    /* Hide table headers */
    th {
      display: none;
    }
  }
  