.App {
  text-align: center;
  background-color: #4a0707;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
/* #bddcfa;    #808B96 E6E6E9*/
.App-header {
  background-color:#850b0b ;
  display: flex;
  flex-direction: column;
  align-items:normal;
  justify-content: left;
  text-align: left;
  font-size: calc(16px + 2vmin);
  vertical-align: center;
  color: rgb(252, 228, 228);
  background-image: url('ffbackground.png');
  background-size: cover;
  background-position: center;
}
